import { Component } from "react";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    // alert(JSON.stringify(error, null, 2));
    // alert(JSON.stringify(errorInfo, null, 2));
    console.log(JSON.stringify(error, null, 2));
    console.log(JSON.stringify(errorInfo, null, 2));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          <pre>{JSON.stringify(this.state.error, null, 2)}</pre>
        </>
      );
    }

    return this.props.children;
  }
}
