import { createContext, useContext, useState, useMemo } from "react";

const AudioContext = createContext();

const AudioContextProviderStore = ({ children }) => {
  const [playingUrl, setPlayingUrl] = useState("no-audio-source-is-playing");

  const value = useMemo(
    () => ({
      playingUrl,
      setPlayingUrl,
    }),
    [playingUrl]
  );

  return (
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
};

export const AudioContextProvider = ({ children }) => {
  return <AudioContextProviderStore>{children}</AudioContextProviderStore>;
};

export const useAudioPlaying = () => {
  const context = useContext(AudioContext);
  if (context === undefined) {
    throw new Error(
      `useAudioPlaying must be used within a AudioPlayingProvider`
    );
  }
  return context;
};
