import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";

const ResolveStory = createContext();

const ResolveStoryProviderStore = ({ children }) => {
  const [resolvedStories, setResolvedStories] = useState({});
  const updateResolvedStories = useCallback(
    (key, stories) =>
      setResolvedStories((prev) => ({ ...prev, [key]: [...stories] })),
    []
  );
  const resetResolvedStories = () => setResolvedStories({});

  useEffect(() => {
    fetch("/api/get-clients", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        const { data, error } = result;
        if (error) {
          console.log("result error", JSON.stringify(error, null, 2));

          return;
        }

        updateResolvedStories("clients", data);
      })
      .catch((error) => {
        console.log("catch ERROR", JSON.stringify(error, null, 2));
      });
  }, [updateResolvedStories]);

  const value = useMemo(
    () => ({
      resolvedStories,
      updateResolvedStories,
      resetResolvedStories,
    }),
    [resolvedStories, updateResolvedStories]
  );

  return (
    <ResolveStory.Provider value={value}>{children}</ResolveStory.Provider>
  );
};

export const ResolveStoryProvider = ({ children }) => {
  return <ResolveStoryProviderStore>{children}</ResolveStoryProviderStore>;
};

export const useResolveStory = () => {
  const context = useContext(ResolveStory);
  if (context === undefined) {
    throw new Error(
      `useResolveStory must be used within a ResolveStoryProvider`
    );
  }
  return context;
};
