import { useEffect } from "react";
import Script from "next/script";
// import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import "normalize.css";
import "../styles/globals.css";

import { ErrorBoundary } from "../src/components/ErrorBoundary";
import { AudioContextProvider } from "../src/hooks/audioContext";
import { ResolveStoryProvider } from "../src/hooks/resolveStoryContext";

function handleExitComplete() {
  if (typeof window !== "undefined") {
    window.scrollTo({ top: 0 });
  }
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    let timeout;
    if (router.route === "/") {
      disableBodyScroll(document.querySelector("body"));
    } else {
      timeout = setTimeout(() => {
        clearAllBodyScrollLocks();
      }, 500);
    }

    return timeout;
  }, [router.route]);

  async function loadPolyfills() {
    if (typeof window.IntersectionObserver === "undefined") {
      await import("intersection-observer");
    }
  }

  useEffect(() => {
    loadPolyfills();
  }, []);

  function detectOperatingSystem() {
    // Verify how to fix this deprecated property
    const platform = window.navigator.platform;

    if (platform.includes('Win')) {
      return 'windows';
    } else if (platform.includes('Mac')) {
      return 'mac';
    } else if (platform.includes('Linux')) {
      return 'linux';
    } else {
      return null;
    }
  }

  useEffect(() => {
    const systemOs = detectOperatingSystem();

    if(systemOs === "windows") { 
      document.documentElement.style.setProperty('--lineHeightCorrectionArticleTitle', 0.09);
      document.documentElement.style.setProperty('--lineHeightCorrectionArticleCarousel', 0.04);
      document.documentElement.style.setProperty('--lineHeightCorrectionStudioOverviewCarousel', 0.068);
    }

  }, []);

  return (
    <ErrorBoundary>
      {/*HOTJAR Tracking Code  */}
      <Script strategy="lazyOnload" id="hotjar">
        {` 
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2547722,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>

      <Script
        id="share-api-polyfill"
        strategy="lazyOnload"
        src="https://unpkg.com/share-api-polyfill/dist/share-min.js"
      />

      {/* <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}> */}
      <AudioContextProvider>
        <ResolveStoryProvider>
          <Component {...pageProps} key={router.route} />
        </ResolveStoryProvider>
      </AudioContextProvider>
      {/* </AnimatePresence> */}
    </ErrorBoundary>
  );
}

export default MyApp;
